import {defineStore} from "pinia";
import {IUserStoreState} from "@/stores/interface";
import piniaPersistConfig from "@/config/piniaPersist";
import router from "@/router";
import {showSuccess} from "@/utils/message";


export const useUserStore = defineStore('user', {
    state: (): IUserStoreState => ({
        uid: 0,
        token: '',
        userName: ''
    }),
    getters: {
        isLogin(state: IUserStoreState) {
            return Boolean(state.token)
        }
    },
    actions: {
        login(token: string, userName: string) {
            this.token = token;
            this.userName = userName;
            showSuccess('登录成功，即将跳转到首页！');
            router.push('/');
        },
        logout() {
            this.token = ''
            this.userName = ''
            this.uid = 0
            router.replace('/')
            showSuccess('退出成功')
        },
        setToken(token: string) {
            this.token = token
        }
    },
    persist: piniaPersistConfig('user')
})