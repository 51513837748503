import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import NProgress from "@/config/nprogress";

const errorRoutes: RouteRecordRaw[] = [{
    path: '/404',
    name: '404',
    component: () => import('@/components/ErrorMessage/404.vue')
}, {
    path: '/403',
    name: '403',
    component: () => import('@/components/ErrorMessage/403.vue')
}, {
    path: '/500',
    name: '500',
    component: () => import('@/components/ErrorMessage/500.vue')
}]
// 个人中心
const userCenterRoutes: RouteRecordRaw[] = [{
    path: '/userCenter',
    redirect: '/myOrder',
    meta: {
        header: true,
        footer: true
    },
    component: () => import('@/views/userCenter/userCenter.vue'),
    children: [
        // 我的订单
        {
            path: '/myOrder',
            name: 'myOrder',
            component: () => import('@/views/userCenter/views/myOrder/myOrder.vue'),
        },
        // 我的关注
        {
            path: '/myAttention',
            meta: {
                header: true,
                footer: true
            },
            component: () => import('@/views/userCenter/views/myAttention/myAttention.vue'),
        },
        // 我的收藏
        {
            path: '/myCollection',
            meta: {
                header: true,
                footer: true
            },
            component: () => import('@/views/userCenter/views/myCollection/myCollection.vue'),
        },
        // 个人信息
        {
            path: '/information',
            meta: {
                header: true,
                footer: true
            },
            component: () => import('@/views/userCenter/views/information/information.vue'),
        },
        // 我的发票
        {
            path: '/myInvoice',
            meta: {
                header: true,
                footer: true
            },
            component: () => import('@/views/userCenter/views/myInvoice/myInvoice.vue'),
        }
    ]
}]
const layoutRoutes: RouteRecordRaw[] = [{
    path: '/layout',
    component: () => import('@/layout/default/index.vue'),
    redirect: '/',
    children: [
        // 首页
        {
            path: '/',
            name: 'index',
            meta: {
                header: true,
                footer: true
            },
            component: () => import('@/views/index/index.vue'),
        },
        // 课程类目页
        {
            path: '/classify',
            name: 'classify',
            meta: {
                header: true,
                footer: true
            },
            component: () => import('@/views/classify/classify.vue'),
        },
        // 购买课程页
        {
            path: '/purchaseOfCourses',
            name: 'purchaseOfCourses',
            meta: {
                header: true,
                footer: true
            },
            component: () => import('@/views/purchaseOfCourses/purchaseOfCourses.vue'),
        },
        // 机构详情页
        {
            path: '/organization',
            name: 'organization',
            meta: {
                header: true,
                footer: true
            },
            component: () => import('@/views/organization/organization.vue'),
        },
        // 机构搜索页
        {
            path: '/organizationSearch',
            name: 'organizationSearch',
            meta: {
                header: true,
                footer: true
            },
            component: () => import('@/views/organizationSearch/organizationSearch.vue'),
        },
        // 排行榜
        {
            path: '/rankings',
            name: 'rankings',
            meta: {
                header: true,
                footer: true
            },
            component: () => import('@/views/rankings/rankings.vue'),
        },
        // 精品秒杀
        {
            path: '/timeLimit',
            name: 'timeLimit',
            meta: {
                header: true,
                footer: true
            },
            component: () => import('@/views/timeLimit/timeLimit.vue'),
        },
        // 讲师详情页
        {
            path: '/teacher',
            name: 'teacher',
            meta: {
                header: true,
                footer: true
            },
            component: () => import('@/views/teacher/teacher.vue'),
        },
        // 我的课程
        {
            path: '/myCourse',
            name: 'myCourse',
            meta: {
                header: true,
                footer: true
            },
            component: () => import('@/views/myCourse/myCourse.vue'),
        },
        // 课程学习页面
        {
            path: '/learningCourses',
            name: 'learningCourses',
            meta: {
                header: true,
                footer: true
            },
            component: () => import('@/views/learningCourses/learningCourses.vue'),
        },
        // 支付页面
        {
            path: '/pay',
            name: 'pay',
            meta: {
                header: true,
                footer: true
            },
            component: () => import('@/views/pay/pay.vue'),
        },
        // 关于我们
        {
            path: '/aboutus',
            name: 'aboutus',
            meta: {
                header: true,
                footer: true
            },
            component: () => import('@/views/aboutus/aboutus.vue'),
        },
        // 关于我们
        {
            path: '/contactUs',
            name: 'contactUs',
            meta: {
                header: true,
                footer: true
            },
            component: () => import('@/views/contactUs.vue'),
        },
        // 个人中心
        ...userCenterRoutes
    ]
}]
const routes: RouteRecordRaw[] = [
    // 未配置路径跳转到首页
    {
        path: '/:pathMatch(.*)',
        redirect: '/404',
    },
    {
        path: '/index',
        redirect: '/',
    },
    // 登录
    {
        path: '/login',
        name: 'login',
        meta: {
            header: false,
            footer: false
        },
        component: () => import('@/views/login/login.vue'),
    },
    // 找回密码
    {
        path: '/forget',
        name: 'forget',
        meta: {
            header: false,
            footer: false
        },
        component: () => import('@/views/forget/forget.vue'),
    },
    {
        path: '/bind',
        name: 'bind',
        meta: {
            header: false,
            footer: false
        },
        component: () => import('@/views/login/bind.vue')
    },
    {
        path: '/oauth/qqcallback',
        name: 'qqcallback',
        meta: {
            header: false,
            footer: false
        },
        component: () => import('@/views/login/oauth/qq.vue'),
    },
    {
        path: '/oauth/wechatcallback',
        name: 'wechatcallback',
        meta: {
            header: false,
            footer: false
        },
        component: () => import('@/views/login/oauth/wx.vue'),
    },
    {
        path: '/oauth/callback',
        name: 'callback',
        meta: {
            header: false,
            footer: false
        },
        component: () => import('@/views/login/oauth/callback.vue'),
    },
    {
        path: '/settleIn',
        name: 'settleIn',
        meta: {
            header: false,
            footer: false
        },
        component: () => import('@/views/userCenter/settleIn/settleIn.vue')
    },
    ...errorRoutes,
    ...layoutRoutes
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // 路由切换后始终滚动到顶部
        return {top: 0}
    },
})

router.beforeEach((to, from, next) => {
    // 1.NProgress 开始
    NProgress.start();

    // if ((to.path === '/login' || to.path === '/bind' || to.path === '/forget')) {
    //     next('/')
    // } else if (to.path === '/oauth/wechatcallback') {
    //     if (to.fullPath.indexOf('code') === -1 && to.fullPath.indexOf('state') === -1) {
    //         next('/')
    //     } else {
    //         next({path: '/oauth/callback', query: {...to.query, type: 'wechatopen'}})
    //     }
    // } else if (to.path === '/oauth/qqcallback') {
    //     if (to.fullPath.indexOf('code') === -1 && to.fullPath.indexOf('state') === -1) {
    //         next('/')
    //     } else {
    //         next({path: '/oauth/callback', query: {...to.query, type: 'qq'}})
    //     }
    // } else if (to.path === '/oauth/callback') {
    //     if (to.fullPath.indexOf('code') === -1 && to.fullPath.indexOf('state') === -1 && to.fullPath.indexOf('type') === -1) {
    //         next('/')
    //     } else {
    //         next()
    //     }
    // } else if (to.path === '/bind') {
    //     if (to.fullPath.indexOf('loginType') === -1 && to.fullPath.indexOf('openId') === -1) {
    //         next('/')
    //     } else {
    //         next()
    //     }
    // } else {
    //     next();
    // }
    next()
});

/**
 * @description 路由跳转错误
 * */
router.onError(error => {
    NProgress.done();
    console.warn("路由错误", error.message);
});

/**
 * @description 路由跳转结束
 * */
router.afterEach((to) => {
    NProgress.done();
});

export default router
