import {createApp} from 'vue'
import App from './App.vue'
import router from './router'

//  样式重置文件
import './assets/css/reset.css'
// 全局样式
import './assets/css/publicStyle.css'
import './assets/css/common.scss'
// 组件库
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// layer 组件
import layer from '@layui/layer-vue';
import '@layui/layer-vue/lib/index.css';
import 'element-plus/theme-chalk/dark/css-vars.css'

import pinia from "@/stores";

// errorHandler
import errorHandler from "@/utils/errorHandler";

// element icons
import * as Icons from "@element-plus/icons-vue";
const app = createApp(App);

app.config.errorHandler = errorHandler;
Object.keys(Icons).forEach(key => {
    app.component(key, Icons[key as keyof typeof Icons]);
});

import zhCn from 'element-plus/es/locale/lang/zh-cn'
app.use(ElementPlus,{locale:zhCn})
app.use(layer).use(router).use(pinia).mount('#app')
