import axios, {AxiosResponse, InternalAxiosRequestConfig} from "axios";
import {showFullScreenLoading, tryHideFullScreenLoading} from "@/config/serviceLoading";
import {checkStatus} from "./helper/checkStatus";
import {useUserStore} from "@/stores/modules/user";
import router from "@/router";
import {HOME_URL, LOGIN_URL} from "@/config";
import {showError} from "@/utils/message";
import {IResData} from "@/services/interface";
import {isArray, isNumber, isObject, isString} from "@/utils/is";
import {apiPaths} from "@/services/config/apiPath";
import {useGlobalStore} from "@/stores/modules/global";

const config = {
    // 设置超时时间
    timeout: 30000,
    // 跨域时候允许携带凭证
    withCredentials: false,
    "Content-Type": 'application/json'
};

// 将返回的json对象中的key转出首字母小写
export const convertResJsonKey = (jsonObj: any): any => {
    let retObj
    if (null === jsonObj) {
        return jsonObj
    } else if (Array.isArray(jsonObj)) {
        // 数组
        retObj = []
        for (const item of jsonObj) {
            retObj.push(convertResJsonKey(item as any))
        }
    } else if (typeof jsonObj === 'object') {
        // json对象
        retObj = {} as any
        for (const key in jsonObj) {
            const value = convertResJsonKey(jsonObj[key])
            const newKey = key.replace(key[0], key[0].toLowerCase())
            retObj[newKey] = value
        }
    } else {
        return jsonObj
    }
    return retObj
}

export interface IReqConfig {
    // 是否显示加载状态
    showLoading?: boolean;
    headers?: any;
    params?: object;
}

class RequestHttp {
    service: any;

    public constructor(config: any) {
        // instantiation
        this.service = axios.create(config);

        /**
         * @description 请求拦截器
         * 客户端发送请求 -> [请求拦截器] -> 服务器
         * token校验(JWT) : 接受服务器返回的 token,存储到 vuex/pinia/本地储存当中
         */
        this.service.interceptors.request.use(
            (config: InternalAxiosRequestConfig) => {
                // 当前请求不需要显示 loading，在 api 服务中通过指定的第三个参数: { showLoading: true } 来控制
                if ((config as IReqConfig).showLoading) {
                    showFullScreenLoading()
                }
                const globalStore = useGlobalStore()
                if (config.url?.includes('zhishitong.net') && globalStore.clientIp) {
                    config.headers['x-real-ip'] = globalStore.clientIp
                }
                const userStore = useUserStore();
                if (config.headers && userStore.isLogin
                    && config.url?.includes('zhishitong.net')) {
                    config.headers['Authorization'] = `Bearer ${encodeURIComponent(userStore.token)}`
                    config.headers['x-clientid'] = encodeURIComponent(userStore.userName)
                }
                return config;
            },
            (error: any) => {
                return Promise.reject(error);
            }
        );

        /**
         * @description 响应拦截器
         *  服务器换返回信息 -> [拦截统一处理] -> 客户端JS获取到信息
         */
        this.service.interceptors.response.use(
            (res: AxiosResponse) => {
                const resData: IResData = {
                    msg: 'success',
                    code: 200,
                    data: ''
                }
                let data = convertResJsonKey(res.data)
                console.log(
                    `-------- res success ------【${new Date().toLocaleTimeString()}】\n`,
                    {
                        url: res.config.url || '',
                        headers: res.config.headers || {},
                        method: res.config.method || '',
                        query: res.config.params || {},
                        params: (() => {
                            const data = res.config.data
                            if (typeof data === 'string') {
                                try {
                                    return JSON.parse(data)
                                } catch (e) {
                                    return data
                                }
                            } else {
                                return data
                            }
                        })(),
                        res: data
                    }
                );

                tryHideFullScreenLoading();
                if (res.config.url?.includes("zhishitong.net/")) {
                    const code = (data.code || 1000000) + ''
                    // 知视通api定义，大写，需要独立处理
                    if (res.config.url?.includes(apiPaths.pay)) {
                        if (data.isSuccess) {
                            resData.data = data.data
                        } else {
                            showError(data.errorMessage)
                            return Promise.reject(data)
                        }
                    } else if ('0' === code) {
                        resData.data = data.data;
                        if (data.refreshToken) {
                            const userStore = useUserStore();
                            userStore.token = data.refreshToken
                        }
                    } else if ('401' === code) {
                        // token无效，重新登录
                        showError('您的登录状态已失效，请重新登录')

                        const userStore = useUserStore();
                        userStore.logout()
                        router.replace(HOME_URL);
                        return Promise.reject(data)
                    } else {
                        try {
                            showError(data.data[0] || '发生未知错误')
                        } catch (e) {
                            showError('发生未知错误')
                        }
                        return Promise.reject(data);
                    }
                } else {
                    if (isString(data) || isArray(data) || isNumber(data)) {
                        resData.data = data
                    } else if (isObject(data)) {
                        resData.data = data
                    }
                }
                // 成功请求（在页面上除非特殊情况，否则不用处理失败逻辑）
                return resData as unknown as AxiosResponse;
            },
            async (error: any) => {
                const res = error.response;
                console.log(
                    `-------- error ------【${new Date().toLocaleTimeString()}】\n`,
                    {
                        url: res?.config.url || '',
                        headers: res?.config.headers || {},
                        method: res?.config.method || '',
                        query: res?.config.params || {},
                        params: (() => {
                            const data = res?.config.data
                            if (typeof data === 'string') {
                                try {
                                    return JSON.parse(data)
                                } catch (e) {
                                    return data
                                }
                            } else {
                                return data
                            }
                        })(),
                        error: error
                    }
                );
                tryHideFullScreenLoading();
                // 请求超时 && 网络错误单独判断，没有 response
                if (error.message.indexOf("timeout") !== -1) showError("请求超时！请您稍后重试")
                if (error.message.indexOf("Network Error") !== -1) showError("网络错误！请您稍后重试")
                // 根据服务器响应的错误状态码，做不同的处理
                if (res) checkStatus(res.status);
                // 服务器结果都没有返回(可能服务器错误可能客户端断网)，断网处理:可以跳转到断网页面
                if (!window.navigator.onLine) router.replace("/500");
                return Promise.reject(error);
            }
        );
    }

    /**
     * @description 常用请求方法封装
     */
    async get<T = any>(url: string, params?: object, config: IReqConfig = {}): Promise<T> {
        try {
            return (await this.service.get(url, {params, ...config}) as IResData<T>).data
        } catch (e) {
            return Promise.reject(e)
        }
    }

    async post<T = any>(url: string, params?: object | string, config: IReqConfig = {}): Promise<T> {
        try {
            return (await this.service.post(url, params, config) as IResData<T>).data
        } catch (e) {
            return Promise.reject(e)
        }
    }

    async put<T = any>(url: string, params?: object, config: IReqConfig = {}): Promise<T> {
        try {
            return (await this.service.put(url, params, config) as IResData<T>).data
        } catch (e) {
            return Promise.reject(e)
        }
    }

    async delete<T = any>(url: string, params?: any, config: IReqConfig = {}): Promise<T> {
        try {
            return (await this.service.delete(url, {params, ...config}) as IResData<T>).data
        } catch (e) {
            return Promise.reject(e)
        }
    }

    async download<T = any>(url: string, params?: object, config: IReqConfig = {}): Promise<T> {
        try {
            return (await this.service.post(url, params, {...config, responseType: "blob"}) as IResData<T>).data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}

export default new RequestHttp(config);
