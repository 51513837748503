import {
    ERROR_DURATION,
    ESHOW_MESSAGE_TYPE, INFO_DURATION,
    SHOW_ERROR_MESSAGE_TYPE,
    SHOW_SUCCESS_MESSAGE_TYPE,
    SUCCESS_DURATION, WARNING_DURATION
} from "@/config";
import {ElMessage, ElNotification} from "element-plus";

const GROUPING = false
const REPEAT_NUM = 1

export const showSuccess = (message: string, msgType = SHOW_SUCCESS_MESSAGE_TYPE, duration = SUCCESS_DURATION) => {
    if (ESHOW_MESSAGE_TYPE.ELMessage === msgType) {
        ElMessage.success({
            message,
            duration,
            grouping: GROUPING,
            repeatNum: REPEAT_NUM
        })
    } else if (ESHOW_MESSAGE_TYPE.ELNotification === msgType) {
        ElNotification.success({
            title: '温馨提示',
            message,
            duration
        })
    }
}

export const showError = (message: string, msgType = SHOW_ERROR_MESSAGE_TYPE, duration = ERROR_DURATION) => {
    if (ESHOW_MESSAGE_TYPE.ELMessage === msgType) {
        ElMessage.error({
            message,
            duration,
            grouping: GROUPING,
            repeatNum: REPEAT_NUM
        })
    } else if (ESHOW_MESSAGE_TYPE.ELNotification === msgType) {
        ElNotification.error({
            title: '温馨提示',
            message,
            duration
        })
    }
}

export const showInfo = (message: string, msgType = SHOW_ERROR_MESSAGE_TYPE, duration = INFO_DURATION) => {
    if (ESHOW_MESSAGE_TYPE.ELMessage === SHOW_ERROR_MESSAGE_TYPE) {
        ElMessage.info({
            message,
            duration,
            grouping: GROUPING,
            repeatNum: REPEAT_NUM
        })
    } else if (ESHOW_MESSAGE_TYPE.ELNotification === msgType) {
        ElNotification.info({
            title: '温馨提示',
            message,
            duration
        })
    }
}

export const showWarning = (message: string, msgType = SHOW_ERROR_MESSAGE_TYPE, duration = WARNING_DURATION) => {
    if (ESHOW_MESSAGE_TYPE.ELMessage === SHOW_ERROR_MESSAGE_TYPE) {
        ElMessage.warning({
            message,
            duration,
            grouping: GROUPING,
            repeatNum: REPEAT_NUM
        })
    } else if (ESHOW_MESSAGE_TYPE.ELNotification === msgType) {
        ElNotification.warning({
            title: '温馨提示',
            message,
            duration
        })
    }
}
