// 不同的域名接口
const metaApi = 'https://metadataapi.zhishitong.net/api/'
const promApi = 'https://promotionapi.zhishitong.net/api/'
const resApi = 'https://resourcesapi.zhishitong.net/api/'
const userApi = 'https://useraccountapi.zhishitong.net/api/'
const merchApi = 'https://merchantapi.zhishitong.net/api/'
const transApi = 'https://transactionapi.zhishitong.net/api/'
// 统一存放接口
export const apiPaths = {
    // 获取客户端ip
    getClickIp: 'https://api.ipify.org/?format=json',
    getAliInfo: 'https://metadataapi.zhishitong.net/api/Platform/GetAliInfo', // 阿里云上传图片获取参数 个人信息页
    getAwsInfo: 'https://adminapi.zhishitong.net/api/Platform/GetAWSInfo',
    // 首页
    getExpressNews: metaApi + 'WebServices/GetExpressNews', // 首页快报
    getCourseCategory: metaApi + 'CourseCategory/GetCourseCategory', // 首页,类目页,个人信息页树形分类
    getAllCourseCategory: metaApi + 'CourseCategory/GetAllCourseCategory', // 个人信息页平铺分类
    getRecommendedTeachers: metaApi + 'Expert/GetRecommendedTeachers', // 首页名师名教
    getPromotion: promApi + 'Promotion/GetPromotion', // 首页精品秒杀，排行榜，精品课程 秒杀页数据 1-热门排行榜 2-精品课程 3-秒杀课程 4-特惠课程
    getLikeCourse: resApi + 'Course/GetLikeCourse', // 首页猜你喜欢,类目页智能推荐
    getRankingList: promApi + 'Promotion/GetRankingList', // 排行榜页数据1-热门排行榜 2-精品课程 3-秒杀课程 4-特惠课程
    // 登录页
    getVerificationCode: userApi + 'UserAccount/GetVerificationCode', // 登录页，忘记密码页，个人信息页获取短信验证码
    userMoblieLogin: userApi + 'UserAccount/UserMoblieLogin', // 登录页手机号登录验证
    verifyIdentity: userApi + 'UserAccount/VerifyIdentity', // 忘记密码页手机号登录验证
    userAccountLogin: userApi + 'UserAccount/UserAccountLogin', // 登录页账号密码登录
    updatePassword: userApi + 'UserAccount/UpdatePassword', // 忘记密码页重置密码
    // 第三方登录
    thirdOAuth: userApi + 'OAuth/', // 第三方授权
    thirdGetQQOpenId: userApi + 'OAuth/qqcallback',
    thirdGetWechatOpenId: userApi + 'OAuth/wechatopencallback',
    thirdLogin: userApi + 'UserAccount/ThirdPartyLogin', // 第三方登录  1qq 2wechat 3wechatopen
    thirdUnbind: userApi + 'UserAccount/UnbindingLogin',// 第三方解绑

    getRecommendCourse: resApi + 'Course/GetRecommendCourse', // 类目页热门推荐
    listCourse: resApi + 'Course/GetCourse', // 类目页获取课程 购买课程页机构其他课程推荐 机构详情页获取全部课程 教师详情页获取全部课程 学习课程页讲师其他课程推荐
    getCourseByCode: resApi + 'Course/GetCourseByCode', // 购买课程页获取课程 学习课程页获取课程
    listCourseClass: resApi + 'Course/GetClasses', // 购买课程页获取课时 学习课程页获取课时
    listCourseComment: resApi + 'Course/GetEvaluate', // 购买课程页获取评论 学习课程页获取评论
    getCustomer: merchApi + 'Merchant/GetCustomer', // 购买课程页获取机构信息 学习课程页获取机构信息
    getCustomerCompleteInfo: merchApi + 'Merchant/GetCustomerCompleteInfo', // 机构详情页获取机构详细信息
    listColumnCourse: resApi + 'Course/GetColumnCourse',  // 机构详情页根据栏目获取课程
    queryCustomer: merchApi + 'Merchant/QueryCustomer', // 搜索机构页获取机构数据
    getExpertById: metaApi + 'Expert/GetExpertById', // 教师详情页获取数据
    getCustomerExpert: metaApi + 'Expert/GetCustomerExpert', // 机构详情页获取教师数据
    getUser: userApi + 'UserAccount/GetUser', // 个人信息页获取用户数据
    updateUser: userApi + 'UserAccount/UpdateUser', // 个人信息页保存修改后的数据
    listSeckillType: promApi + 'Promotion/GetSeckillType', // 秒杀页获取秒杀分类
    getSeckill: promApi + 'Promotion/GetSeckill', // 秒杀页获取根据秒杀分类获取课程
    collection: resApi + 'CourseOperation/Collection', // 购买课程页收藏课程
    clearCollection: resApi + 'CourseOperation/ClearCollection', // 购买课程页取消收藏课程
    attention: merchApi + 'MerchantOperate/Attention', // 机构详情页关注机构
    clearAttention: merchApi + 'MerchantOperate/ClearAttention', // 机构详情页取消关注机构
    getAttention: merchApi + 'Merchant/GetAttention', // 我的关注页获取关注机构列表
    getCollection: resApi + 'Course/GetCollection', // 我的收藏页获取收藏课程列表
    getUserOrders: transApi + 'Performance/GetUserOrders', // 我的课程页获取已购买课程列表
    getCurrentUserOrders: transApi + 'Performance/GetCurrentUserOrders', // 我的课程页获取上次学习课程数据
    isLearningCourseBuy: transApi + 'Order/IsLearningCourseBuy', // 学习课程页判断课程是否购买过
    isCourseBuy: transApi + 'Order/IsCourseBuy', // 学习课程页判断课程是否购买过
    getClassesNote: resApi + 'Course/GetClassesNote', // 学习课程页获取课时学习笔记
    addLearningNotes: resApi + 'CourseOperation/AddLearningNotes', // 学习课程页保存课时学习笔记
    updateLearningNotes: resApi + 'CourseOperation/UpdateLearningNotes', // 学习课程页编辑已有课时学习笔记
    deleteLearningNotes: resApi + 'CourseOperation/DeleteLearningNotes', // 学习课程页删除已有课时学习笔记
    addComment: resApi + 'CourseOperation/Evaluate', // 学习课程页添加评论
    addLearningRecord: resApi + 'CourseOperation/AddLearningRecord', // 学习课程页添加评论

    // 订单相关
    getOrders: transApi + 'Order/GetOrders', // 我的订单页获取订单信息 1-已支付 2-待支付 0-已失效 我的发票页获取订单列表
    getOrderToken: transApi + 'Order/GetToken', // 我的订单页 生成订单时获取token 支付页生成二维码时获取token
    addInvoice: transApi + 'Order/AddInvoice', // 我的订单页申请发票
    refund: transApi + 'Order/Refund', // 我的订单页申请退款
    cancelRefund: transApi + 'Order/CancelRefund', // 我的订单页取消退款
    getInvoice: transApi + 'Order/GetInvoice', // 我的订单页获取发票信息
    getTimestamp: transApi + 'Order/GetTimestamp', // 我的订单页购买课程页 生成订单时获取服务器时间
    getOrdeSign: transApi + 'Order/GetOrdeSign', // 我的订单页购买课程页 生成订单时获取Sign
    addOrder: transApi + 'Order/AddOrder', // 支付页添加订单
    deleteOrder: transApi + 'Order/CancelOrder',// 删除订单
    pay: transApi + 'Order/Pay', // 支付页进行支付生成二维码
    getOrderStateByCode: transApi + 'Order/GetOrderStateByCode', // 支付页生成二维码后查询订单支付状态
}
// 正则
export const publicRegular = {
    password: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,14}$/, // 验证密码正则,至少需要数字+字母,长度在8-14位
    email: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/, // 验证邮箱正则
}
