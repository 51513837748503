// ? 全局默认配置项

// 首页地址（默认）
export const HOME_URL: string = "/";

// 登录页地址（默认）
export const LOGIN_URL: string = "/login";

// 短信发送间隔
export const SMS_SEND_TIMEINTERVAL: number = 60;
// alioss url前缀
export const ALI_OSS_URL: string = "https://zhishitong-bucket.oss-cn-beijing.aliyuncs.com/";
export const ALI_OSS_URL_YLX: string = 'https://ylxue-bucket.oss-cn-beijing.aliyuncs.com/';
// 视频播放地址的 url前缀
export const VIDEO_OSS_URL: string = 'https://video.zhishitong.net/'
// 课程图片
export const IMG_DIR_COURSE = 'Course'
// 讲师头像
export const IMG_DIR_EXPERT = 'Expert'
// 企业logo
export const IMG_DIR_ENTERPRISE_LOGO = 'Enterprise'
// 资质，证书，身份证
export const IMG_DIR_ATTACHMENT = 'Attachment'
// 店铺相关，logo，Banner，详细介绍
export const IMG_DIR_SHOP = 'Shop'
// 用户头像
export const IMG_DIR_USER = 'User'

export enum ESHOW_MESSAGE_TYPE {
    ELMessage,
    ELNotification
}

// 错误提示方式：0=ELMessage;1=ELNotification
export const SHOW_ERROR_MESSAGE_TYPE = ESHOW_MESSAGE_TYPE.ELMessage
export const SHOW_SUCCESS_MESSAGE_TYPE = ESHOW_MESSAGE_TYPE.ELNotification

export const SUCCESS_DURATION = 3000
export const ERROR_DURATION = 3000
export const WARNING_DURATION = 3000
export const INFO_DURATION = 3000

// 用户协议
export const USER_PRIVACY_LINK: string = 'https://www.ylxue.net/Home/Agreement?type=1&companyname=%E6%9C%AC%E5%B9%B3%E5%8F%B0'
// 隐私政策
export const PRIVACY_LINK: string = 'https://www.ylxue.net/Home/Agreement?type=2&companyname=%E6%9C%AC%E5%B9%B3%E5%8F%B0'
