import {defineStore} from "pinia";
import {IGlobalStoreState} from "@/stores/interface";
import piniaPersistConfig from "@/config/piniaPersist";


export const useGlobalStore = defineStore('global', {
    state: (): IGlobalStoreState => ({
        clientIp: '',
        tempData: {
            orderNo: ''
        }
    }),
    getters: {},
    actions: {},
    persist: piniaPersistConfig('global')
})