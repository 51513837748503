import http from '@/services'
import {apiPaths} from "@/services/config/apiPath";
import {IResUser} from "@/services/interface";
import {ESNSLoginType} from "@/types/enums";

export interface IReqSMSLogin {
    mobile: string;
    verificationCode: string;
}

export interface IReqAccountLogin {
    mobile: string;
    password: string;
}

export interface IReqThirdLogin extends IReqSMSLogin {
    loginType: number;
    openId: string;
}

export interface IReqUnbind {
    loginType: number;
    openId: string;
    userId: number;
}

export namespace IReqUser {
    export interface Update {
        headPic: string;
        birthday: string;
        //n昵称
        nickName: string;
        //性别
        sex: number;
        //职业
        occupation: string;
        email: string;
        //个人爱好
        interests: IResUser.InterestsItem[];
    }

    export interface SNSLogin {
        code: string;
        snsType: ESNSLoginType;
    }
}

export const reqSendSMS = (mobile: string) => {
    return http.get(apiPaths.getVerificationCode, {mobile: mobile})
}

export const reqSMSLogin = (params: IReqSMSLogin) => {
    return http.post<IResUser.Login>(apiPaths.userMoblieLogin, params)
}

export const reqAccountLogin = (params: IReqAccountLogin) => {
    return http.post<IResUser.Login>(apiPaths.userAccountLogin, params)
}

export const reqBindThird = (params: IReqThirdLogin) => {
    return http.post<IResUser.Login>(apiPaths.thirdLogin, params)
}

export const reqReBindSNS = (params: IReqUnbind) => {
    return http.post<IResUser.Login>(apiPaths.thirdUnbind, params)
}

export const reqThirdOauth = (snsType: ESNSLoginType) => {
    return http.get<string>(apiPaths.thirdOAuth + (snsType === ESNSLoginType.QQ ? 'qq' : 'wechatopen'))
}

export const reqThirdLogin = (params: IReqUser.SNSLogin) => {
    return http.get<IResUser.Login>(params.snsType === ESNSLoginType.QQ ? apiPaths.thirdGetQQOpenId : apiPaths.thirdGetWechatOpenId, {code: params.code})
}

export const reqGetUserInfo = () => {
    return http.get<IResUser.UserInfo>(apiPaths.getUser)
}

export const reqUpdateUser = (params: IReqUser.Update) => {
    return http.post(apiPaths.updateUser, params)
}