// 第三方登录类型
export enum ESNSLoginType {
    QQ = 1,
    Wechat = 3
}

// 订单状态 1-已支付 2-待支付 0-已失效
export enum EOrderState {
    Finish = 1,
    Appending = 2,
    Expired = 0
}

//退款状态 3-未申请退款 2-申请退款 1-通过退款 0-退款拒绝
export enum ERefundState {
    None = 3,
    Applied = 2,
    Success = 1,
    Reject = 0
}

// 支付方式 //1-支付宝 0-微信支付
export enum EPayWay {
    Alipay = 1,
    Wechat = 0
}

// 发票抬头类型 0-个人 1-单位
export enum EInvoiceTitleType {
    Personal = 0,
    Enterprise = 1
}

// 发票状态 //0-拒绝(作废) 1-审核通过 2-待审核 3-驳回（未申请）
export enum EInvoiceState {
    Cancel = 0,
    Success = 1,
    Appending = 2,
    Reject = 3
}

// 自定义表单模式：0=添加，1=编辑，3=查看(只读)
export enum ECustomFormMode {
    Add = 0,
    Edit = 1,
    View = 2
}
